<template>
  <section class="acquisitions">
    <h2 class="acquisitions__section-title text-title">Acquisitions</h2>

    <router-link
      v-for="item in acquisitions"
      :key="`art-${item.id}`"
      class="acquisitions__item"
      :to="{ path: `/artworks/${item.slug}` }"
    >
      <h3 class="acquisitions__item-acquired-title">{{ item.acquired_name }}</h3>
      <div
        class="acquisitions__item-image"
        :style="{
          backgroundImage: `url(${item.search_image})`,
        }"
      >
        <div class="acquisitions__item-image-preview"><p>Click to View</p></div>
      </div>
      <p class="acquisitions__item-artist">{{ item.artist }}</p>
      <div class="acquisitions__item-container">
        <span class="acquisitions__item-title text-description">{{ item.title }}</span>
        <span v-if="item.materials" class="acquisitions__item-materials text-description">
          , {{ item.materials }}
        </span>
        <span class="acquisitions__item-years text-description">, {{ item.years }}</span>
      </div>
    </router-link>

    <BaseButton
      v-if="hasMorePages"
      class="acquisitions__button button--xs button--white"
      text="LOAD MORE"
      @click.native="loadMore"
    />
  </section>
</template>

<script>
import { mapMutations, mapActions, mapGetters } from "vuex";
import BaseButton from "@/components/buttons/BaseButton.vue";

export default {
  components: { BaseButton },

  data() {
    return {
      page: 1,
    };
  },
  head: {
    title: function () {
      return {
        inner: "Acquisitions browngrotta arts",
      };
    },
    meta: function () {
      return [
        {
          name: "description",
          itemprop: "description",
          content: "Acquisitions browngrotta arts",
          id: "meta:description",
        },
        {
          itemprop: "name",
          content: "Acquisitions browngrotta arts",
          id: "meta:name",
        },
        {
          itemprop: "image",
          content: `${process.env.VUE_APP_FRONTEND_URL}/img/icons/favicon-500x500.png`,
          id: "meta:image",
        },
        {
          property: "og:url",
          content: `${process.env.VUE_APP_FRONTEND_URL}${this.$route.path}`,
          id: "og:url",
        },
        {
          property: "og:type",
          content: "website",
          id: "og:type",
        },
        {
          property: "og:title",
          content: "Acquisitions browngrotta arts",
          id: "og:title",
        },
        {
          property: "og:image",
          content: `${process.env.VUE_APP_FRONTEND_URL}/img/icons/favicon-500x500.png`,
          id: "og:image",
        },
        {
          property: "og:image:alt",
          content: "Acquisitions browngrotta arts",
          id: "og:image:alt",
        },
        {
          property: "og:description",
          content: "Description Acquisitions browngrotta arts",
          id: "og:description",
        },
        {
          property: "og:site_name",
          content: process.env.VUE_APP_FRONTEND_URL,
          id: "og:site_name",
        },
        {
          property: "og:locale",
          content: "en_US",
          id: "og:locale",
        },
        {
          name: "twitter:card",
          content: "summary",
          id: "twitter:card",
        },
        {
          name: "twitter:site",
          content: "@BrowngrottaArts",
          id: "twitter:site",
        },
        {
          name: "twitter:creator",
          content: "@BrowngrottaArts",
          id: "twitter:creator",
        },
        {
          name: "twitter:url",
          content: `${process.env.VUE_APP_FRONTEND_URL}${this.$route.path}`,
          id: "twitter:url",
        },
        {
          name: "twitter:title",
          content: "Acquisitions browngrotta arts",
          id: "twitter:title",
        },
        {
          name: "twitter:description",
          content: "Description Acquisitions browngrotta arts",
          id: "twitter:description",
        },
        {
          name: "twitter:image",
          content: `${process.env.VUE_APP_FRONTEND_URL}/img/icons/favicon-500x500.png`,
          id: "twitter:image",
        },
        {
          name: "twitter:image:alt",
          content: "Acquisitions browngrotta arts",
          id: "twitter:image:alt",
        },
      ];
    },
  },

  async created() {
    await this.apiGetAcquisitionsList(this.page);

    const breadcrumbs = [
      {
        title: "Home",
        to: "/",
      },
      {
        title: "Media",
        to: "/media",
        clickable: true,
      },
      {
        title: "Acquisitions",
        to: "/media/acquisitions",
        clickable: false,
      },
    ];
    this.setBreadcrumbs(breadcrumbs);
  },

  computed: {
    ...mapGetters("publicapi/artworks", {
      acquisitions: "getAcquisitionsList",
      hasMorePages: "getHasMorePages",
    }),
  },

  methods: {
    ...mapActions("publicapi/artworks", ["apiGetAcquisitionsList"]),
    ...mapMutations(["setBreadcrumbs"]),

    loadMore() {
      if (this.hasMorePages) {
        this.page += 1;
        this.apiGetAcquisitionsList(this.page);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.acquisitions {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  padding: 31rem 2.5rem 20rem;

  &__section-title {
    font-size: 40px;
    line-height: 1.25;
    margin-bottom: 80px;
  }

  &__item {
    width: 35vw;
    display: block;
    margin-bottom: 85px;
    text-decoration: auto;
    cursor: pointer;
    @media screen and (max-width: $lg) {
      width: 60vw;
    }
    @media screen and (max-width: $xs) {
      width: 100%;
    }

    &-acquired-title {
      font-size: 24px;
      line-height: 1.2;
      font-weight: 500;
      letter-spacing: -0.8px;
      margin-bottom: 2.6rem;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      line-clamp: 2;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    &-image {
      position: relative;
      height: 35vw;
      width: 100%;
      background-size: cover;
      background-position: center;
      border-radius: 4px;
      margin-bottom: 1.3rem;
      @media screen and (max-width: $lg) {
        height: 60vw;
      }
      @media screen and (max-width: $xs) {
        height: 90vw;
      }

      &-preview {
        position: absolute;
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-self: center;
        opacity: 0;
        border-radius: 4px;
        transition: all 0.3s ease;

        p {
          width: 100%;
          text-align: center;
          font-size: 3rem;
          line-height: 1.67;
          letter-spacing: -1px;
        }
      }

      &:hover {
        .acquisitions__item-image-preview {
          opacity: 1;
          background-color: rgba($color: $blue, $alpha: 0.83);
        }
      }
    }

    &-artist {
      font-size: 24px;
      line-height: 1.2;
      font-weight: 500;
      letter-spacing: -0.8px;
      margin-bottom: 0.65rem;
    }

    &-container {
    }
    &-title {
      font-weight: 500;
    }

    &-materials {
      font-style: italic;
    }
  }

  &__button {
    min-width: 180px;
  }
}
</style>
